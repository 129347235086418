import moment from "moment";
import { axios } from "../../../../lib/axios";

export const updateAppointmentRequest = (payload) => {
    return axios.put("appointment", { ...payload, isFromWeb: true, active: true })
}

export const getAppointments = ({practitionerId, status}) => {
    return axios.get(`appointment?practitionerId=${practitionerId}&status=${status}`);
};
export const createEncounter = ({ patient, practitioner, appointmentId, appointmentTime, orgId, questionnaireInfo, statusHistory, patientLocation}) => {
    const payload = {
        subject: patient,
        status: "in-progress",
        period: {
            start: appointmentTime
        },
        patientLocation: (patientLocation) ? patientLocation : questionnaireInfo?.location,
        participant: [
            {
                individual: practitioner,
                period: {
                    start: moment().utc().format()
                }
            }
        ],
        type: "encounter",
        appointmentId,
        managingOrganization: {
            reference: `Organization/${orgId}`,
            type: "Organization",
        },
        intakeQuestions: questionnaireInfo,
        statusHistory: statusHistory
    }
    return axios.post("encounter", payload)
}

export const searchEncounters = ({orgId, start, end, type, status, encounterId, practitionerId, page, pageSize }) =>{
    // minimum url
    let url = `encounter/search?orgId=${orgId}`;
    url += (start) ? `&DateStart=${start}`:"";
    url += (end) ? `&DateEnd=${end}`:"";
    url += (type) ? `&type=${type}`:"";
    url += (encounterId) ? `&encounterId=${encounterId}`:"";
    url += (status) ? `&status=${status}`:"";
    url += (practitionerId) ? `&practitionerID=${practitionerId}`:"";
    url += (page) ? `&page=${page}`:"";
    url += (pageSize) ? `&pageSize=${pageSize}`:"";
    return axios.get(url);
}

export const createEncounterInbound = ({ patient, status, practitionerId, practitionerName, proceedWith, orgId, questionnairInfo, requestedPeriod, statusHistory, patientLocation }) => {
    const payload = {
        "subject": patient,
        "managingOrganization": {
            "reference": `Organization/${orgId}`,
            "type": "Organization"
        },
        "practitionerId": practitionerId,
        "participant": [
            {
                "individual": {
                    "reference": `Practitioner/${practitionerId}`,
                    "type": "Practitioner",
                    "display": practitionerName
                }
            }
        ],
        "period": requestedPeriod,
        "type": [
            {
                "coding": [
                    {
                        "code": "VV",
                        "display": "Virtual Visit Encounter Type"
                    }
                ]
            }
        ],
      //  "questionnaireResponse": questionnairInfo,
        "patientLocation": (patientLocation) ? patientLocation : questionnairInfo?.location,
        "intakeQuestions": questionnairInfo,
        "proceedWith": proceedWith,
        "status" : status,
        "statusHistory": statusHistory
    }
    return axios.post("encounter", payload)
}

export const editEncounter = ({encounterId, status, encounterClass, patient, practitionerId, practitionerName, proceedWith, orgId, questionnairInfo, requestedPeriod, patientLocation, statusHistory, actor, participant}) => {    
    const defaultParticipant = [
        {
            "individual": {
                "reference": `Practitioner/${practitionerId}`,
                "type": "Practitioner",
                "display": practitionerName
            }
        }
    ];

    const participantArray = participant ? participant : defaultParticipant;


    
    const payload = {
        "subject": patient,
        "managingOrganization": {
            "reference": `Organization/${orgId}`,
            "type": "Organization"
        },
        "practitionerId": practitionerId,
        "participant": participantArray,
        "requestedPeriod": requestedPeriod,
        "type": [
            {
                "coding": [
                    {
                        "code": "VV",
                        "display": "Virtual Visit Encounter Type"
                    }
                ]
            }
        ],
     //   "questionnaireResponse": questionnairInfo, 
        "intakeQuestions": questionnairInfo,
        "proceedWith": proceedWith,
        "status" : status,
        "patientLocation": (patientLocation) ? patientLocation : questionnairInfo?.location,
        "statusHistory": statusHistory,
        "actor": actor,
        "class": encounterClass ? encounterClass : ""
    }
    return axios.put(`encounter/${encounterId}`, payload)
}

export const addAddendumToEncounter = ({encounterId, addenda}) => {
    return axios.put(`encounter/${encounterId}`, {note: addenda})
}

export const bookAppointment = ({serviceCategory, requestType, slot, practitionerId, patientId, requestedPeriod, description, comment, sessionToken, isReschedule = false, document = null, intakeQuestions, patientLocation, scheduleId, newEncounterIdFromModal }) => {
    const payload = {
        requestType: (requestType) ? requestType : practitionerId ? "provider" : "organization",
        status: "proposed",
        description,
        practitionerId,
        comment,
        patientId,
        requestedPeriod,
        document: null,
        isFromWeb: true,
        sessionToken,
        isReschedule,
        intakeQuestions,
        patientLocation,
        scheduleId,
        slot,
        serviceCategory,
        active: true,
        newEncounterId: newEncounterIdFromModal ? newEncounterIdFromModal : null

    }
    return axios.post("appointment", payload)
}

export const rescheduleAppointment = ({ serviceCategory, keepOldSlot, intakeQuestions, slot, appointmentId, requestedPeriod, practitionerId, description }) => {
    const payload = {
        appointmentId,
        requestedPeriod,
        practitionerId,
        description,
        requestor: `Practitioner/${practitionerId}`,
        slot,
        keepOldSlot,
        serviceCategory,
        intakeQuestions,
        active: true
    }
    return axios.post("appointment/reschedule", payload)
}
export const checkSchedule = ({ actorId, date, orgId }) => {
    return axios.get(`schedule/check?actorId=${actorId}&date=${date}${orgId ? `&orgId=${orgId}`:``}${date ? `&endDate=${moment(date).add(1,'M').format('YYYY-MM-DD')}`:""}`)
}
export const getlistPractitioners = ({ orgId, scopeOfPractice }) => {
    return axios.get(`practitioner?org_id=${orgId}&scopeOfPractice=${scopeOfPractice}`)
}
export const getSlots = ({ actorId, date, serviceCategoryId, orgId, scopeOfPractice  }) => {
    return axios.get(`schedule/patient?actorId=${actorId}&date=${date}&serviceCategoryId=${serviceCategoryId}${orgId ? `&orgId=${orgId}`:``}${scopeOfPractice ? `&scopeOfPractice=${scopeOfPractice}`:``}`)
}


export const getEncounterRecord = ({ encounterId }) => {
    return axios.get(`encounter/${encounterId}`)
};
export const getQueueRecord = ({encounterId, userOrganizationId}) => {
    return axios.get(`queue/${encounterId}?orgId=${userOrganizationId}`)
};
export const getEncounterQuestionnaireResponses = ({encounterId}) => {
    return axios.get(`/questionnaireresponse?encounterId=${encounterId}`)
};

export const getPractitionerDataForQueue = ({practitionerId}) => {
    return axios.get(`/practitioner/${practitionerId}`)
};

export const getAppointmentRecord = ({appointmentId}) => {
    return axios.get(`/appointment/${appointmentId}`)
}