import React, { useEffect, useState } from 'react'
import { FullPageSpinner } from '../common/Spinner/FullPageSpinner'
import { useGetOrgLicensedProducts } from '../../hooks/ReactQueryHooks/useGetOrgLicensedProducts';
import { Button } from '../common/Button';
import { AddNote } from './AddNote';
import AddNoteIcon from '../../assets/images/add-note.png';
import CallIcon from "../../assets/images/call-border.png"
import labIcon from '../../assets/images/chemistry.png';
import medicalIcon from '../../assets/images/medical-prescription.png';
import diagnosticIcon from '../../assets/images/analytics.png';
import referralIcon from '../../assets/images/referral.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { failed, success } from '../common/Toastify';
import { useSelector } from 'react-redux';
import { getOrganization } from '../api/Organization';
import SelectFiled from '../common/textfield/SelectFiled';
import { QuestionnaireFormikObj } from './constant';
import { isAnswered } from '../../utils/questionnaireSupport';
import { useFormik } from 'formik';
import { createQuestionnaireResponse, editQuestionnaireResponse, getSavedQuestionnaireResponses, getVideoVisitQuestionnaire } from '../api/Questionnaire';
import "./PhoneCallCreate.css"
import { editQueueItem, endWaitingRoomMeeting } from '../apps/api/WaitingRoom';
import { axios } from '../../lib/axios';
import moment from 'moment';
import { editEncounter, getEncounterRecord, getQueueRecord, updateAppointmentRequest, getEncounterQuestionnaireResponses } from '../apps/api/AppointmentRequests';
import { getSingleUser } from '../api/Individual';
import QuestionnaireRenderer from '../Admin/Questionnaire/QuestionnaireRenderer'
import { useEncounterQuestionnaireResponseQuery } from '../../hooks/ReactQueryHooks/useQuestionnaireResponseQuery';
import ScheduleAppointmentWR from '../apps/Queue/ScheduleAppointmentWR';
import EncounterDetail from "../apps/Patient/EncounterDetail";

function PhoneCallCreate() {
    const { state } = useLocation();    
    const {encounterId, currentUser, practitionerName, appointmentData, practitionerId, patientId, patientName, proceedWith, requestedPeriod, period, questionnairInfo, previousStatus } = state
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const orgId = adminOrgs?.[0]?.id;
    const [encounterDetailsShow, setEncouterDetailsShow]= useState(false);
    const [encounterFinished, setEncounterFinished] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [isDisabled, setIsDisabled] = useState(false)
    const [orgnization, setOrganizations] = useState({})
    const [addNoteModal, setAddNoteModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false)
    const [textEditorKey, setTextEditorKey] = useState(0);
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(questionnaireList.length && questionnaireList[0]?.id || '');
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userRoles?.includes('Super-Admin');
    const { isFetched, data: orgLicensedProducts } = useGetOrgLicensedProducts({})
    const isVirtualCare = admin ? false : orgLicensedProducts?.virtualCare
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const user = useSelector((state) => state?.auth?.user);
    const [practionerData, setPractionerData] = useState({});
    const [myUserId, setMyUserId] = useState("");
    const [encounterRecord, setEncounterRecord] = useState({});
    const [savedQuestionnaireResponses, setSavedQuestionnaireResponses] = useState([]);
    const [savedQuestionResponseId, setSavedQuestionResponseId] = useState("");
    const practUserName = useSelector(
        (state) => state?.auth?.user?.name[0]?.text
      );
    const [queueRecord, setQueueRecord] = useState({});

    const navigate = useNavigate();
    const [scheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
    const formik = useFormik({
        ...QuestionnaireFormikObj,
        onSubmit: (values, { setErrors, setFieldValue }) => {
            return new Promise((resolve, reject) => {
                if (values?.questionResponse.length === 0 || values.questionResponse === undefined) {
                    failed("Save questionnaire before proceeding");
                    return;
                }
    
                for (let i = 0; i < values?.questionResponse.length; i++) {
                    const item = values?.questionResponse[i];
                    if (item.required && !isAnswered(item)) {
                        setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                        reject(`Question : ${item.question} is mandatory.`);
                        return;
                    }
                }
    
                setBtnLoading(true);
                setIsDisabled(false);
                let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId")) || {};
                let prevQuestionnaireResponse =JSON.parse(localStorage.getItem("questionnaireResponse")) || {}
                let QRid = values.questionResponseId  || questionResponseId?.[selectedQuestionnaire];
                if(prevQuestionnaireResponse?.[selectedQuestionnaire]){
                    editQuestionnaireResponse(QRid, { patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId: "" , questionnaire: selectedQuestionnaire})
                    .then((res) => {
                        setFieldValue("questionResponseId", res.data.id)
                        prevQuestionnaireResponse[selectedQuestionnaire] = values?.questionResponse
                        questionResponseId[selectedQuestionnaire] = QRid
                        localStorage.setItem("questionnaireResponse",JSON.stringify(prevQuestionnaireResponse))
                        localStorage.setItem("questionResponseId",JSON.stringify(questionResponseId))
                        setSavedQuestionnaireResponses(getEncounterQuestionnaireResponses({ encounterId: encounterId }));
                        success(res.message);
                        resolve(res);
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                        reject(res);
                    })
                    .finally(() => {
                        setBtnLoading(false);
                    });
                }else{
                    createQuestionnaireResponse({ patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId: "", questionnaire: selectedQuestionnaire})
                    .then((res) => {
                        setFieldValue("questionResponseId", res.data.id)
                        prevQuestionnaireResponse[selectedQuestionnaire] = values?.questionResponse
                        questionResponseId[selectedQuestionnaire] = res.data.id
                        localStorage.setItem("questionnaireResponse",JSON.stringify(prevQuestionnaireResponse))
                        localStorage.setItem("questionResponseId",JSON.stringify(questionResponseId))
                        setSavedQuestionnaireResponses(getEncounterQuestionnaireResponses({ encounterId: encounterId }));
                        success(res.message);
                        resolve(res);
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                        reject(res);
                    })
                    .finally(() => {
                        setBtnLoading(false);
                    });
                }
               
            });
        },
    });

    // useEffect(() => {
    //     setIsLoading(true);
    //     setLoadingText("Loading...");
    //     getSavedQuestionnaireResponses(encounterId)
    //     .then((res) => {
    //       setSavedQuestionnaireResponses(res?.data)
    //       const matchedQuestionnaireData = res?.data.find(dataItem => 
    //         questionnaireList.some(q => q.id === dataItem.questionnaire)
    //       );
    //       if (res?.data.length > 1) {
    //         setSavedQuestionResponseId(matchedQuestionnaireData?.id);
    //         formik.setFieldValue("questionnaire", matchedQuestionnaireData?.questionnaire);
    //         setSelectedQuestionnaire(matchedQuestionnaireData?.questionnaire);


    //         const questionResponse = questionnaireList
    //         .filter(item => item?.id === matchedQuestionnaireData?.questionnaire)?.[0]?.item
    //         .map((item, index) => ({
    //             id: item?.linkId,
    //             question: item?.text,
    //             key:{index},
    //             questionType: item?.answerType,
    //             answerType: item?.answerType,
    //             answerOption: item?.answerOption?.map(x => ({value: ((x.value) ? x.value : x.text), name: (x.text) ? x.text: x.value, text: x.text, option: (x.value) ? x.value : x.text})),
    //             answer: item.answerOption?.map((item, answerIndex) => ({ 
    //                 answer: matchedQuestionnaireData?.item[index]?.answer[answerIndex]?.valueString || matchedQuestionnaireData?.item[index]?.answer[answerIndex]?.valueBoolean || "", 
    //                 id: item?.id, 
    //                 option: (item?.value) ? item.value : item?.text 
    //             })),
    //             required: item?.required,
    //         })) || [];
    //         formik.setFieldValue("questionResponse", questionResponse);
            
    //     }
        
    //     })
    //     .catch((res) => {
    //         failed(
    //           res?.response?.data?.message ||
    //             res?.response?.data?.error ||
    //             res.message
    //         );
    //     })
    //     .finally(() => setIsLoading(false))
    // }, [questionnaireList]);

    useEffect(() => {
        const apiBody = {
          id: user?.["custom:unique_id"],
          type: "Practitioner",
          userOrgId: adminOrgs?.[0]?.id
        };
        getSingleUser(apiBody)
          .then((res) => {
            setPractionerData(res?.data);
            setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
          })
          .catch((res) => {
            console.log("error in image upload",res)
          })
        return () => {
        localStorage.removeItem("questionResponseId");
        localStorage.removeItem("questionnaireResponse");
        }
      }, []);

    useEffect(() => {
        getEncounterRecord({encounterId: encounterId})
        .then((res) => {
            setEncounterRecord(res?.data);
            if (res?.data?.id) {
                getQueueRecord({encounterId: encounterId, userOrganizationId: orgId})
                .then((res) => {
                    if (res?.data?.id) {
                        setQueueRecord(res?.data)
                    }
                })
            }
        })
    }, [])

    const handleServiceRequest = (requestType) => {
        window.open(`/app/encounter-service-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&patientName=${patientName}&patientId=${patientId}&requestType=${requestType}&fromPatient=false`, '_blank')
    }
    const handleMedicationRequest = () => {
        window.open(`/app/encounter-medication-request?encounterId=${state?.encounterId}&practitionerName=${practitionerName}&practitionerId=${practitionerId}&patientName=${patientName}&patientId=${patientId}&fromPatient=false`, '_blank')
    }

    const setEmptyQuestionnaire = (qId) => {
        return questionnaireList
            .filter(item => item?.id === qId)?.[0]?.item
            .map((item , index) => ({
                id: item?.linkId,
                question: item?.text,
                key:{index},
                questionType: item?.answerType,
                answerType: item?.answerType,
                answerOption: item?.answerOption?.map(x => ({value: ((x.value) ? x.value : x.text), name: (x.text) ? x.text: x.value, text: x.text, option: (x.value) ? x.value : x.text})),
                answer: item.answerOption?.map(item => ({ answer: "", id: item?.id, option: (item?.value) ? item.value : item?.text })),
                required: item?.required,
            }))
    }

    const setPreviousQuestionnaire = (questionResponse) => {
        return questionResponse?.item?.map((questionItem, index) => ({
            id: questionItem?.linkId,
            question: questionItem?.text,
            key: { index },
            questionType: questionItem?.questionType,
            answerType: questionItem?.questionType,
            answerOption: questionItem?.answer?.map(x => ({
                value:  x?.option,
                name: x?.option,
                text: x?.option,
                option: x?.option
            })),
            answer: questionItem?.answer?.map(answerItem => ({
                answer: answerItem?.valueString || answerItem?.ratingText || answerItem?.valueBoolean || "",
                id: questionItem?.linkId,
                option: answerItem?.option || answerItem?.valueString
            })),
            required: questionItem?.required
        }));
    };
    

    const handleSelectChange = async (event) => {
        let questionResponse = [];
        const selectedQuestionnaireId = event.target.value;
    
        try {  
            if (previousStatus  === "follow-up"){  
                let questionnaireResponse = JSON.parse(localStorage.getItem("questionnaireResponse"));
                if (questionnaireResponse?.[selectedQuestionnaireId]) {
                    questionResponse = questionnaireResponse[selectedQuestionnaireId];
                } else {
                    questionResponse = setEmptyQuestionnaire(selectedQuestionnaireId);
                }   

            } else {
                let latestResponse = [];
                if (savedQuestionnaireResponses && savedQuestionnaireResponses.data) {
                    const filteredData = savedQuestionnaireResponses.data
                        .filter(record => record.questionnaire === selectedQuestionnaireId)
                        .sort((a, b) => b.authored - a.authored);

                    latestResponse = filteredData.length > 0 ? filteredData[0] : [];
                }
                let questionnaireResponse = JSON.parse(localStorage.getItem("questionnaireResponse"));
                if (questionnaireResponse?.[selectedQuestionnaireId]) {
                    questionResponse = questionnaireResponse[selectedQuestionnaireId];
                } else {
                    questionResponse = (latestResponse?.item?.length > 0) 
                        ? setPreviousQuestionnaire(latestResponse) 
                        : setEmptyQuestionnaire(selectedQuestionnaireId);
                    if (latestResponse?.item?.length > 0) { 
                        let prevQuestionnaireResponse = { };
                        let questionResponseId = { };
                        formik.setFieldValue("questionResponseId", latestResponse.id)
                        prevQuestionnaireResponse[selectedQuestionnaireId] = questionResponse;
                        questionResponseId[selectedQuestionnaireId] = latestResponse.id;  
                        localStorage.setItem("questionnaireResponse",JSON.stringify(prevQuestionnaireResponse))
                        localStorage.setItem("questionResponseId",JSON.stringify(questionResponseId))  
                    }
                }   
            }

            formik.setFieldValue("questionResponse", questionResponse); 
            setSelectedQuestionnaire(selectedQuestionnaireId);
            setTextEditorKey(prevKey => prevKey + 1);
            
    
        } catch (error) {
            // Handle any errors in the API call
            console.error("Error fetching questionnaire responses:", error);
        }
    };
    

    const handleEndSession = async() => {
        if(questionnaireList?.length > 0){
        let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId")) || {};
        let QRid = formik?.values?.questionResponseId  || questionResponseId?.[selectedQuestionnaire]
        if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined || !QRid) {
            failed("Save questionnaire before proceeding");
            return;
        }
        for (let i = 0; i < formik.values?.questionResponse.length; i++) {
            const item = formik.values?.questionResponse[i];
            if (item.required && !isAnswered(item)) {
                formik.setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                return;
            }
        }
        } else {
            if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined) {
                failed("Save questionnaire before proceeding");
                return;
            }
        }
        resetQuestionnaire();
        if(encounterFinished){
            setEncouterDetailsShow(true);
            return;
        }
        setIsLoading(true)
        setLoadingText("Ending Session")
        localStorage.removeItem("userJoined")
        state?.isWaitingRoom && endWaitingRoomMeeting({ id: state?.id, patientId: state?.patientId }).then((res) => success(res.message)).catch((err) => console.log(err))
        const appintmentTime = [{start: moment(requestedPeriod?.[0]?.start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}]
        const encounterData = await axios.get(`encounter/${state?.encounterId}`);
        const encounterPeriod = encounterData?.data?.period
        if (appointmentData || appointmentData?.id) {
          return updateAppointmentRequest({encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: ""})
            .then(async(res) => {
                if(res.status === true){
                    const actor = {
                        display: practionerData.name[0].text,
                        reference: `Practitioner/${practitionerId}`,
                        type: "Practitioner"
                    };
                    return axios.put(`encounter/${state?.encounterId}`, {
                        status: encounterData?.data?.status,
                        actor: actor,
                        proceedWith,
                        requestedPeriod: appintmentTime,
                        // questionnaireResponse: questionnairInfo,
                        intakeQuestions: questionnairInfo,
                        class: encounterData?.data?.class,
                        period: {start: encounterPeriod?.start, end: null }
                      }).then((res) => {
                        setIsLoading(false);
                        setLoadingText(false);
                        if (proceedWith !== "WAITING_ROOM") {
                            setEncouterDetailsShow(true);
                            setEncounterFinished(true);                        }
                      })
                      .catch((err) =>console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                      .finally(() =>    setIsLoading(false))
                }
             
            }).catch((err) =>console.log(err?.response?.data?.message ||err?.response?.data?.error || err?.message));
            
        } else {
            try {
                const actor = {
                    display: practionerData.name[0].text,
                    reference: `Practitioner/${practitionerId}`,
                    type: "Practitioner"
                };

                const res = await axios.put(`encounter/${encounterId}`, {
                  status: encounterData?.data?.status,
                  proceedWith,
                  requestedPeriod: appintmentTime,
                  intakeQuestions: questionnairInfo,
                  actor: actor,
                  class: encounterData?.data?.class,
                  period: {
                    start: encounterPeriod?.start,
                    end: null,
                  },
                });
                setIsLoading(false);
                setLoadingText(false);
                setEncouterDetailsShow(true);
                setEncounterFinished(true);  
              } catch (err) {
                console.error('Error:', err);
                console.error('Error response data:', err?.response?.data);
                setIsLoading(false);
              }
        }
    }

    useEffect(() => {
        getOrganization(adminOrgs?.[0]?.id)
            .then((res) => {
                setOrganizations(res.data);
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getOrganization(adminOrgs?.[0]?.id);
                const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
                const videoVisitIds = assignedQuestionnaire["video-visit"] || [];
                if('video-visit' in assignedQuestionnaire && videoVisitIds.length > 0) {
                    const VVRes = await getVideoVisitQuestionnaire(JSON.stringify(videoVisitIds), orgId)
                    const modifiedData = VVRes?.data?.map(item => ({
                        ...item,
                        name: item.title,
                        value: item.id
                    }));
                    setQuestionnaireList(modifiedData);
                }
                const resQuestionnaireResponse = await getEncounterQuestionnaireResponses({ encounterId: encounterId }); 
                setSavedQuestionnaireResponses(resQuestionnaireResponse);
                
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        };
        fetchData();
    }, []);

    const resetQuestionnaire = async () => {
        formik.resetForm();
        setSelectedQuestionnaire('')
    };

    const findQrId = (id) => {
        let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId"))
        questionResponseId = questionResponseId?.[id] || null
        if (questionResponseId){
            return questionResponseId
        }
        return false;
    }

    const handleView = () => {
        const baseUrl = window.location.origin;
        const patientIdView = patientId;
        const patientNameView = patientName;
        const url = `${baseUrl}/app/patients-details?patientId=${patientIdView}&patientName=${patientNameView}`;
        window.open(url, "_blank");
    };

    const handleParkCallClick = () => {
        if(questionnaireList?.length > 0){
            let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId")) || {};
            let QRid = formik?.values?.questionResponseId  || questionResponseId?.[selectedQuestionnaire]
            if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined || !QRid) {
                failed("Save questionnaire before proceeding");
                return;
            }
            for (let i = 0; i < formik.values?.questionResponse.length; i++) {
                const item = formik.values?.questionResponse[i];
                if (item.required && !isAnswered(item)) {
                    formik.setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                    return;
                }
            }
            } else {
                if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined) {
                    failed("Save questionnaire before proceeding");
                    return;
                }
        }  
        formik.handleSubmit();
        setIsLoading(true);
        setLoadingText("Loading...");

        let QRid =  savedQuestionResponseId || formik?.values?.questionResponseId  || localStorage.getItem("questionResponseId");
        // if(QRid){
        //     editQuestionnaireResponse(QRid, { patientID: patientId, encounterId: encounterId, questionResponse: formik.values?.questionResponse, appointmentId: "" , questionnaire: selectedQuestionnaire})
        //         .then((res) => {
        //             formik.setFieldValue("questionResponseId", res.data.id)
        //             success(res.message);
        //         })
        //         .catch((res) => {
        //             failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        //         })
        //         .finally(() => {
        //             setIsLoading(false);
        //         });
        // }
        // else{
        //     createQuestionnaireResponse({ patientID: patientId, encounterId: encounterId, questionResponse: formik.values?.questionResponse, appointmentId: "", questionnaire: selectedQuestionnaire})
        //     .then((res) => {
        //         formik.setFieldValue("questionResponseId", res.data.id)
        //         localStorage.setItem("questionResponseId", JSON.stringify(res.data.id));
        //         success(res.message);
        //       })
        //    .catch((res) => {
        //       failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
        // }

        const utcString = new Date().toISOString().split(".")[0] + ".00Z";

        if(state?.appointmentData) {
            const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
            return updateAppointmentRequest({ encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: "" })
                .then(async(res) => { 
                    if(res?.status === true){
                        const actor = {
                            display: practionerData.name[0].text,
                            reference: `Practitioner/${practitionerId}`,
                        };
                        let questionnareResponses = appointmentData?.intakeQuestions;
                        let newResponseAdded;
                        if (questionnareResponses) {
                            newResponseAdded = {
                                ...questionnareResponses,
                                intakeCommunicationChannel: "phone",
                                intakeReason: appointmentData?.intakeQuestions?.reason,
                                intakeAssignedPractitioner: {
                                    individual: {
                                        display: practionerData?.name[0]?.text,
                                        reference: `Practitioner/${practitionerId}`,
                                        type: "Practitioner"
                                    },
                                    period: {
                                        start: utcString,
                                        end: null
                                    }
                                }                       
                            }
                        }
                        await axios.put(`encounter/${state?.encounterId}`, { status: "parked", actor: actor, requestedPeriod, class: "NULL", intakeQuestions: newResponseAdded })
                        // setEncouterDetailsShow(true);
                        // setEncounterFinished(true);
                        success("Appointment successfully parked");
                        navigate("/app/dashboard");
                    }
                })
                .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))            
        }
        else {
            const currentActor = {
                reference: `Practitioner/${myUserId}`,
                display: practUserName,
                type: "Practitioner",
            };
            let encounterIntakeQuestions = {
                ...encounterRecord?.intakeQuestions,
                intakeCommunicationChannel: "phone",
                intakeAssignedPractitioner: {
                    individual: {
                        display: practionerData?.name[0]?.text,
                        reference: `Practitioner/${practitionerId}`,
                        type: "Practitioner"
                    },
                    period: {
                        start: utcString,
                        end: null
                    }
                }
            }

            let encounterPayload = {
                patient: encounterRecord?.subject,
                orgId: encounterRecord?.orgId,
                questionnairInfo: encounterIntakeQuestions,
                requestedPeriod: encounterRecord?.requestedPeriod,
                practitionerId: encounterRecord?.practitionerId,
                practitionerName: practUserName,
                proceedWith: encounterRecord?.proceedWith,
                status: "parked",
                encounterId: encounterRecord?.id,
                patientLocation: encounterRecord?.patientLocation,
                participant: encounterRecord?.participant,
                actor: currentActor,
                encounterClass: "NULL"
            };
            setIsLoading(true);
            let oldPractHistory = queueRecord?.practitionerHistory;
            if (oldPractHistory) {
                let parkingPractitioner = {
                    actor: {
                        display: practionerData?.name[0]?.text,
                        reference: `Practitioner/${myUserId}`,
                        type: "Practitioner"
                    },
                    assignedBy: {
                        actor: {
                            display: practionerData?.name[0]?.text,
                            reference: `Practitioner/${myUserId}`,
                            type: "Practitioner"
                        },
                    },
                    updatedAt: Date.now()
                }
                const updatedPractitionerHistory = [
                    ...oldPractHistory,
                    parkingPractitioner,
                ];
                editQueueItem(
                    {
                        id: queueRecord?.id,
                        practitionerHistory: updatedPractitionerHistory,
                    },
                    orgId
                ).then((res) => {
                    }).catch((res) =>
                        failed(
                        res?.response?.data?.message ||
                        res?.response?.data?.error ||
                        res?.message
                    )
                );
            }

            editEncounter(encounterPayload)
            .then((res) => {
                if (res?.status === true) {
                    success("Encounter Parked Successfully");
                    navigate("/app/dashboard");
                }
            })
            .catch((res) =>
                failed(
                res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res.message
                )
            )
            .finally(() => setIsLoading(false));
        }
    }

    const handleFollowUpClick = () => {

        if(questionnaireList?.length > 0){
            let questionResponseId = JSON.parse(localStorage.getItem("questionResponseId")) || {};
            let QRid = formik?.values?.questionResponseId  || questionResponseId?.[selectedQuestionnaire]
            if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined || !QRid) {
                failed("Save questionnaire before proceeding");
                return;
            }
            for (let i = 0; i < formik.values?.questionResponse.length; i++) {
                const item = formik.values?.questionResponse[i];
                if (item.required && !isAnswered(item)) {
                    formik.setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                    return;
                }
            }
            } else {
                if (formik.values?.questionResponse.length === 0 || formik.values.questionResponse === undefined) {
                    failed("Save questionnaire before proceeding");
                    return;
                }
        }    
        formik.handleSubmit();
        setIsLoading(true);
        setLoadingText("Loading...");

        //let QRid = formik?.values?.questionResponseId  || localStorage.getItem("questionResponseId")
        // if(QRid){
        //     editQuestionnaireResponse(QRid, { patientID: patientId, encounterId: encounterId, questionResponse: formik.values?.questionResponse, appointmentId: "" , questionnaire: selectedQuestionnaire})
        //         .then((res) => {
        //             formik.setFieldValue("questionResponseId", res.data.id)
        //             success(res.message);
        //         })
        //         .catch((res) => {
        //             failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        //         })
        //         .finally(() => {
        //             setIsLoading(false);
        //         });
        // }
        // else{
        //     createQuestionnaireResponse({ patientID: patientId, encounterId: encounterId, questionResponse: formik.values?.questionResponse, appointmentId: "", questionnaire: selectedQuestionnaire})
        //     .then((res) => {
        //         formik.setFieldValue("questionResponseId", res.data.id)
        //         localStorage.setItem("questionResponseId", JSON.stringify(res.data.id));
        //         success(res.message);
        //     })
        //     .catch((res) => {
        //         failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
        // }

        if(state?.appointmentData) {
            const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
            return updateAppointmentRequest({ encounterId: state?.encounterId, id: state?.appointmentData?.id, status: "completed", patientId, practitionerId, sessionToken: "" })
                .then(async(res) => { 
                    if(res?.status === true){
                        const actor = {
                            display: practionerData.name[0].text,
                            reference: `Practitioner/${practitionerId}`,
                        };
                        let questionnareResponses = appointmentData?.intakeQuestions;
                        let newResponseAdded;
                        if (questionnareResponses) {
                            newResponseAdded = {
                                ...questionnareResponses,
                                intakeCommunicationChannel: "phone",
                                intakeReason: appointmentData?.intakeQuestions?.reason
                            }
                        }
                        await axios.put(`encounter/${state?.encounterId}`, { status: "follow-up", actor: actor, requestedPeriod, class: "NULL", intakeQuestions: newResponseAdded })
                        // setEncouterDetailsShow(true);
                        // setEncounterFinished(true);
                        success("Appointment added to Follow-Up");
                        navigate("/app/dashboard");
                    }
                })
                .catch((err) => console.log(err?.response?.data?.message || err?.response?.data?.error || err?.message))            
        }
        else {
            const currentActor = {
                reference: `Practitioner/${myUserId}`,
                display: practUserName,
                type: "Practitioner",
            };
            let encounterIntakeQuestions = {
                ...encounterRecord?.intakeQuestions,
                intakeCommunicationChannel: "phone"
            }

            let encounterPayload = {
                patient: encounterRecord?.subject,
                orgId: encounterRecord?.orgId,
                questionnairInfo: encounterIntakeQuestions,
                requestedPeriod: encounterRecord?.requestedPeriod,
                practitionerId: encounterRecord?.practitionerId,
                practitionerName: practUserName,
                proceedWith: encounterRecord?.proceedWith,
                status: "follow-up",
                encounterId: encounterRecord?.id,
                patientLocation: encounterRecord?.patientLocation,
                participant: encounterRecord?.participant,
                actor: currentActor,
                encounterClass: "NULL"
            };
            setIsLoading(true);

            editEncounter(encounterPayload)
            .then((res) => {
                if (res?.status === true) {
                    success("Encounter Added to Follow-Up");
                    navigate("/app/dashboard");
                }
            })
            .catch((res) =>
                failed(
                res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res.message
                )
            )
            .finally(() => setIsLoading(false));
        }
    }  

    const handleScheduleAppointmentClick = () => {
        setScheduleAppointmentModalOpen(true);
    }
    

  return (
    encounterDetailsShow ?  
        <EncounterDetail  setEncouterDetailsShow = {setEncouterDetailsShow} EncounterId = {state?.encounterId} PatientId= {patientId} PractionerData= {practionerData} PractitionerId = {practitionerId} ShowCompleteButton = {true} />
        :
    <div>
        <section className="common-listing">
            {isLoading && <FullPageSpinner loadingText={loadingText} />}
            {scheduleAppointmentModalOpen && <ScheduleAppointmentWR onShow={scheduleAppointmentModalOpen} onHide={() => setScheduleAppointmentModalOpen(false)} encounterId={encounterId} questionnaireData={questionnairInfo} patientName={patientName} patientId={patientId} questionnaireResponseId={savedQuestionResponseId} />}
            <div style={{display: "flex" , justifyContent: "space-between"}}>
                <div className="head-wrap">
                        <h1>
                            <span className="big-head" style={{fontSize: "24px", textTransform: "capitalize"}}>{currentUser?.patient?.display}</span>
                        </h1>
                        <div style={{marginLeft : "10px"}}>
                            <img src={CallIcon} alt='call'/>
                            <span>{questionnairInfo?.callerNumber}</span>
                        </div>
                </div>
                <div className="heading-wrap mb-3">
                    {state?.isWaitingRoom ? null : <div className="right-wrap">
                    
                    <Button className="custom-btn" title="Open Dashboard in a new tab" variant="primary" onClick={() => handleView()}>Patient Dashboard</Button>
                    <Button className="custom-btn" title="Schedule Appointment" variant="primary" onClick={() => handleScheduleAppointmentClick()}>Schedule Appointment</Button>
                    <Button className="custom-btn ms-2" title="Add Note" variant="primary" style={{ padding: "0" }} onClick={() => setAddNoteModal(true)}>+<img src={AddNoteIcon} alt="Prev Arrow" /></Button>
                        <AddNote
                            show={addNoteModal}
                            encounterId={state?.encounterId}
                            patientName={currentUser?.patient?.display}
                            onHide={() => setAddNoteModal(false)}
                        />
                        {isVirtualCare && permissions.includes("Create Prescriptions") && (<Button className="custom-btn ms-2" title="Create Prescription" variant="primary" style={{ padding: "0" }} onClick={()=>  orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery) ?  handleMedicationRequest() : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={medicalIcon} alt="Prev Arrow" /></Button>)}
                        {isVirtualCare && permissions.includes("Create Lab Requisitions") && (<Button className="custom-btn ms-2" title="Add Lab service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Lab") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.") }><img src={labIcon} alt="Prev Arrow" /></Button>)}
                        {isVirtualCare && permissions.includes("Create Diagnostic Imaging Requests") && (<Button className="custom-btn ms-2" title="Add Diagnostic service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Diagnostic") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={diagnosticIcon} alt="Prev Arrow" /></Button>)}
                        {isVirtualCare && permissions.includes("Create Service Referrals") && (<Button className="custom-btn ms-2" title="Add Referral service request" variant="primary" style={{ padding: "0" }} onClick={() => orgnization?.sft?.enabled && (orgnization?.sft?.individualDelivery || orgnization?.sft?.locationDelivery)? handleServiceRequest("Referral") : (orgnization?.sft?.enabled && (!orgnization?.sft?.individualDelivery && !orgnization?.sft?.locationDelivery)) ? failed("Please enable SFT delivery options.") : failed("Please enable SFT to send document.")}><img src={referralIcon} alt="Prev Arrow" /></Button>)}
                        <Button className="custom-btn ms-2" title={"Proceed"} variant="primary" onClick={handleEndSession}>Proceed</Button>
                    </div>}
                </div>
            </div>
            <div className="questionnair-wrapper">
                <div className="heading-wrap h-change" style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 className="big-head">Questionnaire</h1>
                </div>
                <hr />
                <div className="chat-sidebar" >
                    <div className="individual-chart-wrap">
                        <div className="chat-body common-form" style={{ paddingTop: "1rem", justifyContent: "center", alignItems: "center" }}>
                            <SelectFiled
                                keyField={"questionnaire"}
                                label={"Select Questionnaire"}
                                formik={formik}
                                readOnly={isDisabled}
                                options={questionnaireList}
                                onChange={handleSelectChange}
                                value={selectedQuestionnaire} 
                            />
                            <hr />
                            <QuestionnaireRenderer formik={formik} questionResponse={formik.values.questionResponse} parentIndex={textEditorKey} />
                             
                        </div>

                        <div className="msg-footer">
                            <div className="btn-wrap" style={{ display: 'flex', justifyContent: "end", gap: "1rem" }}>
                                
                                <Button type="submit" isLoading={btnLoading} onClick={handleParkCallClick}>Park Call</Button>
                                <Button type="submit" isLoading={btnLoading} onClick={handleFollowUpClick}>Add to Follow-Up</Button>
                                <Button type="submit" isLoading={btnLoading} onClick={formik.handleSubmit} style={{background: 'linear-gradient(90deg, #4CAF50 0%, #388E3C 100%)'}} >{findQrId(selectedQuestionnaire) ? "Update" : "Save"}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default PhoneCallCreate
